import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translations'


function load(component) {
	// '@' is aliased to src/components
	return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: {
				template: "<router-view></router-view>"
		},
		children: [{
						path: '',
						name: 'Exchange',
						component: load('ExchangeFormPage')
				},
				// {
				// 	path: 'exchange',
				// 	name: 'Exchange',
				// 	component: load('ExchangeFormPage')
				// },
				{
						path: 'payment',
						name: 'Payment',
						component: load('PaymentPage')
				}
		]
	},	
	{
		path: '/:locale',
		component: {
				template: "<router-view></router-view>"
		},
		beforeEnter: Trans.routeMiddleware,
		children: [{
						path: '',
						name: 'Exchange',
						component: load('ExchangeFormPage')
				},
				// {
				// 	path: 'exchange',
				// 	name: 'Exchange',
				// 	component: load('ExchangeFormPage')
				// },
				{
						path: 'payment',
						name: 'Payment',
						component: load('PaymentPage')
				}
		]
	},
	{
		path: '*'

	}
]

// const routes = [
// 	{
// 		path: '/:locale',
// 		name: 'Exchange',
// 		component: () => import('@/views/ExchangeFormPage.vue')
// 	},
// 	{
// 		path: '*',
// 		redirect: '/',
// 		// component: () => import('@/views/ExchangeFormPage.vue')
// 	},
// 	{
// 		path: '/payment',
// 		name: 'Payment',
// 		component: () => import('@/views/PaymentPage.vue')
// 	},
// 	{
// 		path: '/ru',
// 		name: 'ExchangeRu',
// 		component: () => import('@/views/ExchangeFormPage.vue')
// 	},
// 	{
// 		path: '/ru/payment',
// 		name: 'PaymentRu',
// 		component: () => import('@/views/PaymentPage.vue')
// 	},
// ]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
