<template>
 <div> 
  <p
    
    @click="switchLocale()"
    class="loc bold_locale" 
    :class="switcherClass"
    
    >
      {{otherLocale.toUpperCase()}}
    </p>
 </div>
 
  
 
</template>
<script>
import { Trans } from '@/plugins/Translations'
// import { mapGetters } from 'vuex'
export default {  
  name: 'LocaleSwitcher',
  data(){
    return {
      switcherClass: ""
    }
  },
  computed: {
    supportedLocales () {
      return Trans.supportedLocales
    },
    otherLocale(){
      return this.$i18n.locale === 'en' ? 'ru' : 'en'
    }
  },    
  methods: {
    switchLocale() {     
        const locale = this.otherLocale
        localStorage.setItem('currentPkoinLocale', locale);
        const to = this.$router.resolve({ params: {locale} })    
        console.log('to!!!!', to)    ;
        return Trans.changeLocale(locale).then(() => { 
          this.$router.push(to.location)
          console.log(this.$i18n.locale)          
        })
    },
  },

  created(){

    const defaultLanguage = navigator.language;
    
    const loc = localStorage.getItem('currentPkoinLocale') || defaultLanguage;

    if (this.$route.path === '/' && loc && loc !== this.$i18n.locale){

      return Trans.changeLocale(loc).then(() => { 
        // this.$router.push(to.location)
        console.log(this.$i18n.locale)          
      })
    }
  }
}

</script>
<style lang="sass" scoped>
div
  width: 50px
  height: 50px
  display: flex  
  justify-content: center
  align-items: center
     
  .bold_locale
    font-weight: 700
    width: 30px
    height: 30px
    border-radius: 50%
    margin-left: 15px
    transition: transform .2s ease-in-out
    transform: scale(1.3)
    cursor: pointer
    &:hover
      text-shadow: 1px 1px 15px rgba(255, 255, 255, 0.7)
    

  .delete_locale
    display: none



</style>