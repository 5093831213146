<template>
    <div class="footer">

        <span @click="displayModal" :class="`footer_link ${changeColor}`">{{$t('interface.contact_support')}}</span>
        <span @click="displayPutchaseGuide" :class="`footer_link ${changeColor}`">{{$t('interface.user_guide')}}</span>
        <span v-if="setButtonVisibility" @click="navigateToPaymentForm" :class="`footer_link ${changeColor}`">{{$t('interface.back_to_payment_form')}}</span>
        <span v-if="setButtonVisibility" @click="displayDeletionModal" :class="`footer_link ${changeColor}`">{{$t('interface.clear_transaction_history')}}</span>
        <span v-else @click="navigateToHistory" :class="`footer_link ${changeColor}`">{{$t('interface.purchase_history')}}</span>

        <Dialog header="Header" :visible.sync="display" >
            <template #header>
                <!-- <h3>$t('interface.SupportTicket')</h3> -->
                <h3>{{$t('interface.Support_Ticket')}}</h3>
            </template>

            <SupportModal @closeModal="displayModal" />

        </Dialog>

        <Dialog header="Clear history" :visible.sync="display_delete_confirmation" >
            <span>{{$t('interface.clear_history_apply')}}</span>

            <div class="delete-btns">
                <Button @click.native="clearTransactionHistory" :label="$t('interface.yes')" class="p-button-outlined yes" />
                <Button @click="displayDeletionModal" :label="this.$t('interface.no')" class="p-button-outlined p-button-danger" />
            </div>
        </Dialog>

        <Dialog :header="$t('interface.users_guide_title')" :visible.sync="display_purchase_guide" >
        <section>
            <h4>{{$t('interface.common_information_title')}}</h4>
            <p>- {{$t('interface.copy_wallet_title')}}</p>
            <p>- {{$t('interface.amount_of_pkoin_title')}}</p>
            <br>
            <br>

            <h4>{{$t('interface.possible_transaction_title')}}</h4>
            <p><span class="await bold">{{$t('interface.awaiting_funds')}}</span> - {{$t('interface.waiting_title')}}</p>
            <p><span class="expired bold">{{$t('interface.expired')}}</span> - {{$t('interface.transaction_timer_title')}}</p>
            <p><span class="completed bold">{{$t('interface.completed')}}</span> - {{$t('interface.your_funds_title')}}</p>
            <p><span class="pending bold">{{$t('interface.pending')}}</span> - {{$t('interface.something_has_happened_title')}}.</p>
            <p><span class="сonfirm bold">{{$t('interface.AWAITING_CONFIRMATIONS')}}</span> - {{$t('interface.appeared_title')}}.</p>
            <p><span class="сonfirm bold">{{$t('interface.a_lot_confirms')}}</span> - {{$t('interface.a_number')}}.</p>
        </section>
            <!-- <UsersGuide /> -->
        </Dialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog'
import SupportModal from '@/components/SupportModal.vue'
import UsersGuide from '@/components/UsersGuide.vue'
import Button from 'primevue/button'
import LocaleSwither from '@/components/LocaleSwither.vue'
import { Trans } from '@/plugins/Translations'


export default {
    name: 'PageFooter',
    props: {
        color: String
    },
    components: {
        Dialog,
        SupportModal,
        Button,
        UsersGuide,
        LocaleSwither
    },

    computed: {
        changeColor() {
            if(this.color === 'gray') return 'footer_link_gray'
            if(this.color === 'white') return 'footer_link_white'
        },

        includeNoEmptyFields() {
            return this.ticket_data.address && this.ticket_data.subject && this.ticket_data.text && this.ticket_data.email
        },

        setButtonVisibility() {
            if(this.$route.path === '/en/payment' || this.$route.path === '/ru/payment') return true

            return false
        },

        isTransactionHistoryEmpty() {
            return Object.keys(localStorage.getItem('transaction_history'))
        }
    },

    methods: {
        displayDeletionModal() {
            this.display_delete_confirmation = !this.display_delete_confirmation
        },

        displayPutchaseGuide() {
            this.display_purchase_guide = !this.display_purchase_guide
        },

        displayModal() {
            this.display = !this.display
        },

        navigateToHistory() {            
            this.$router.push(this.$i18nRoute({ name: 'Payment' })).catch(error => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    !error.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(error)
                }
            })
            // this.$router.push({name: 'Payment'}) 
            // console.log(this.$i18nRoute({ name: 'Payment' }))
            localStorage.removeItem('new_transaction')
        },

        navigateToPaymentForm() {
            this.$router.push(this.$i18nRoute({ name: 'Exchange' }))
            // this.$router.push('/')
        },

        clearTransactionHistory() {
            localStorage.setItem('transaction_history', JSON.stringify({}))
            this.$emit('historyDelete')
            this.displayDeletionModal()
        },
    },

    data() {
        return {
            display: false,
            display_delete_confirmation: false,
            ticket_data: null,
            ticket_validation_results: null,
            display_purchase_guide: false
        }
    }
}
</script>
s
<style lang="sass" scoped>
.footer
    display: flex
    justify-content: center
    font-weight: bold

.footer_link
    font-size: 0.9em
    color: $font-gray
    text-decoration: none
    cursor: pointer
    margin: 0 $r * 2

.footer_link_gray
    color: $font-gray

.footer_link_white
    color: white

.delete-btns
    padding: 1em 1em 1em 0

.yes
    margin-right: 1em

::v-deep .p-dialog .p-dialog-header
    padding: $r $r * 2.2
    background: white
    color: gray

::v-deep .p-dialog .p-dialog-header h3
    margin: 0

::v-deep .p-dialog .p-dialog-content
    padding-bottom: $r * 2

.await
    color: $main-blue

.expired
    color: red

.completed
    color: green
    
.pending
    color: orange

.сonfirm
    color: blue

.bold
    font-weight: bold

section
    line-height: .5
    p
        line-height: 1

@media screen and (max-width: 768px)
    .footer
        display: flex
        flex-wrap: wrap
        line-height: 3
</style>