/*
--fix
Здесь по-хоршему ссылки на иконки нужно добавить в объекты массива currencies
При попытке добавить их туда приложение падало, поэтому как временное решение ссылки хранятся в coin_logos

Из-за такой организации хранения ссылок, в коде можно встретить функцию-костыль getCurrencyIcon()
Если собрать всю информацию в currencies, то этот костыль можно будет убрать

*/

const parameters = {
    press: [
        {
            id: 1,
            logo_link: require('@/assets/images/press/bitcoin.com_1.png'),
            site_link: 'https://news.bitcoin.com/bastyon-crypto-social-network-integrates-tor-network-and-releases-post-advertising-for-pocketcoin-pkoin/?preview_id=531824',
            publish_date: 'Jun 24, 2022'
        },
        {
            id: 2,
            logo_link: require('@/assets/images/press/cointelegraph.png'),
            site_link: 'https://cointelegraph.com/press-releases/pocketnet-pkoin-releases-blockchain-video-and-live-crypto-challenge',
            publish_date: 'May 28, 2021'
        },
        {
            id: 3,
            logo_link: require('@/assets/images/press/entrepreneur.png'),
            site_link: 'https://www.entrepreneur.com/article/424434',
            publish_date: 'Apr 11, 2022'
        },
        
        {
            id: 4,
            logo_link: require('@/assets/images/press/cointelegraph.png'),
            site_link: 'https://cointelegraph.com/news/how-the-bitcoin-model-can-solve-the-social-media-dilemma',
            publish_date: 'Sep 06, 2021'
        },
        {
            id: 5,
            logo_link: require('@/assets/images/press/themerkle.png'),
            site_link: 'https://themerkle.com/while-steemit-is-having-blockchain-issues-check-out-pocketnet/',
            publish_date: 'Sep 2, 2019'
        },
        {
            id: 6,
            logo_link: require('@/assets/images/press/bitcoin.com_1.png'),
            site_link: 'https://news.bitcoin.com/bastyon-inventor-daniel-satchkov-explains-the-importance-of-using-an-open-source-social-media-platform/',
            publish_date: 'Jun 24, 2022'
        },
        
    ],
    cash:[
        {name: 'Mastercard', code: 'Mastercard'},
        {name: 'Visa', code: 'Visa'},
        {name: 'Indacoin', code: 'Indacoin'},
    ],
    indacoin_logo:[
        {name:'Mastercard', link: require('@/assets/images/Mastercard-Logo.png')},
        {name:'Visa', link: require('@/assets/images/visa-5-logo-black-and-white.png')},
        {name:'Indacoin', link: require('@/assets/images/Indacoin-logo.png')},
    ],
    currencies: [
        // {name: 'Mastercard/Visa', code: 'MC/Visa'},
        {name: 'Bitcoin (BTC)', code: 'BTC'},
        {name: 'Ethereum (ETH)', code: 'ETH'},
        {name: 'USDT ERC-20 (Ethereum network)', code: 'USDT'},
        {name: 'USDT TRC-20 (Tron network)', code: 'USDTT'},
        {name: 'Dogecoin (DOGE)', code: 'DOGE'},
        {name: 'RIPPLE ', code: 'XRP' },
        {name: 'Litecoin (LTC)', code: 'LTC'},
        {name: 'Stellar (XLM)', code: 'XLM'},
        {name: 'TRON (TRX)', code: 'TRX'},
        {name: 'Dash (DASH)', code: 'DASH'},
        {name: 'SUI', code: 'SUI'},

    ],

    coin_logos: [
        {name:'BTC', label:'BTC', link:'https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=013'},
        {name:'ETH', label:'ETH', link:'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=013'},
        {name:'USDT', label:'USDT', link:'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=013'},
        {name:'USDTT', label:'USDT', link:'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=013'},
        {name:'DOGE', label:'DOGE', link:'https://cryptologos.cc/logos/dogecoin-doge-logo.svg?v=013'},
        {name:'XRP', label:'RIPPLE', link:'https://cryptologos.cc/logos/xrp-xrp-logo.svg?v=013'},
        {name:'LTC', label:'LTC', link:'https://cryptologos.cc/logos/litecoin-ltc-logo.svg?v=013'},
        {name:'XLM', label:'XLM', link:'https://cryptologos.cc/logos/stellar-xlm-logo.svg?v=013'},
        {name:'TRX', label:'TRX', link:'https://cryptologos.cc/logos/tron-trx-logo.svg?v=013'},
        {name:'DASH', label:'DASH', link:'https://cryptologos.cc/logos/dash-dash-logo.svg?v=013'},
        {name:'SUI', label:'SUI', link:'https://cryptologos.cc/logos/sui-sui-logo.svg?v=035'}
        
    ]
}

export default parameters
