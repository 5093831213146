<template>
<div id="app">
	<!-- <LocaleSwither /> -->
	<Toast position="top-left" />
	<router-view></router-view>
	<!-- <HeaderValues/> -->
	<!-- <PageFooter class="footer" color="white" /> -->
</div>
</template>

<script>
import Vue from 'vue'
import Toast from 'primevue/toast'
import PageFooter from '@/components/PageFooter.vue'
import HeaderValues  from '@/components/HeaderValues'
import LocaleSwither from '@/components/LocaleSwither.vue'
Vue.component('Toast', Toast)

export default {
	components: {
		PageFooter,
		HeaderValues,
		LocaleSwither
	}
}
</script>


<style lang="sass">
body
	margin: 0 auto
	// overflow: hidden

#app 
	// height: 100%
	font-family: $Segoe
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	color: #2c3e50
	padding: 0

.fade-enter-active, .fade-leave-active
	transition: opacity 2s

.fade-enter, .fade-leave-to
	opacity: 0

</style>
