import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'

import ToastService from 'primevue/toastservice'
import VueClipboard from 'vue-clipboard2'
import Tooltip from 'primevue/tooltip'
// çç

Vue.use(PrimeVue)
Vue.use(ToastService)
Vue.use(VueClipboard)
Vue.use(Tooltip)

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import { i18n } from '@/i18n'
import { Trans } from '@/plugins/Translations'


Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,    
    render: h => h(App)
}).$mount('#app')



