<template>
    <transition v-if="header_is_ready" name="fade">
        
        <div class="header_col">
            <div>
                <div class="cash_header">
                    <div class="header_item_wrapper" 
                    v-for="(logo, i) in inda_logos" 
                    :key="i"
                    @click="checkDropdown(logo.name)"
                    >
                        <img class="logo" :src="logo.link" alt="coin_logo">
                        <span class="header_item">{{ logo.label }}</span>
                    </div>
                    <button @click="goToIndacoin()">{{$t('interface.credit_card')}}</button>
                </div>
            </div>
            <div>
                <div class="header">
                    <div class="header_item_wrapper" 
                    v-for="(logo, i) in logos" 
                    :key="i"
                    @click="checkDropdown(logo.name)">
                        <img class="logo" :src="logo.link" alt="coin_logo">
                        <img 
                            v-if="logo.name === 'USDTT'"
                            class="small-icon" src="https://cryptologos.cc/logos/tron-trx-logo.svg?v=013" alt="coin_logo"
                        >
                        <img 
                            v-if="logo.name === 'USDT'"
                            class="small-icon" src="https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=013" alt="coin_logo"
                        >
                        <span class="header_item">{{ logo.label }}</span>
                    </div>
                </div>
            </div> 
            <div class="locale_class">
                <LocaleSwither/>
            </div> 
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import parameters from '@/assets/parameters.js'
import LocaleSwither from '@/components/LocaleSwither.vue'


export default {
    name: 'HeaderValues',
    components: {
        LocaleSwither,
    },
    props: {
        color: String,
        
    },
    computed: {
        ...mapGetters([
            'rates',
            'selected_currency'
        ]),

        changeColor() {
            if(this.color === 'gray') return 'header_link_gray'
            if(this.color === 'white') return 'header_link_white'
        }
    },

    methods: {
        ...mapActions([
            'getCurrencyRates',
            'setSelectedCurrency',
        ]),
        
        checkDropdown: function(name_img){
            if(name_img === 'Mastercard' || name_img === 'Visa' || name_img === 'Indacoin'){
                window.open('https://buy.pkoin.indacoin.io/', '_blank');
            } else{    
                const arr = parameters.currencies.filter(item => item.code === name_img);   
                this.currency = arr[0];
                console.log(this.currency);
                this.$store.commit('SET_SELECTED_CURRENCY', this.currency);
                document.getElementById('wallet_address').focus()                      
                
            }
            
        },
        goToIndacoin: function(){
            window.open('https://buy.pkoin.indacoin.io/')
        }
    },

    mounted() {
        setTimeout(() => this.header_is_ready = true, 600)
    },
    

    data() {
        return {
            pkoin_data: null,
            exchange_rates: null,
            header_is_ready: false,
            logos: parameters.coin_logos,
            inda_logos: parameters.indacoin_logo,            
        }
    },

    async created() {
        await this.getCurrencyRates()
        this.pkoin_data = this.rates.find(item => item.currency === 'PKOIN')
        this.exchange_rates = this.rates.filter(item => item.currency !== 'PKOIN')
        
        
    }
}

</script>

<style lang="sass" scoped>
.header_col
    display: flex
    justify-content: center
    padding: 20px 0
    .locale_class
        width: 100px
    div
        &:nth-child(2)
            width: 450px
            
        display: flex
        justify-content: center
        // flex-wrap: wrap
        // flex-wrap: wrap
        width: 300px
        .cash_header
            // width: 50%
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            button
                width: 100%
                background: transparent
                border: none
                font-size: 1.1em
                // padding-top: 10px
                text-align: center
                color: #fff
                font-weight: 500
                cursor: pointer
                transition: .5s 
                &:hover            
                    color: #2196F3
            


            .header_item_wrapper
                display: flex
                flex-direction: column
                align-items: center
                padding: $r
                width: 70px
                transition: transform .2s ease-in-out

                &:hover
                    transform: scale(1.3)            

                .logo
                    width: 60px
                    height: 35px

                .header_item
                    font-size: 0.7em
                    text-decoration: none

        .header
            width: 100%
            display: flex
            justify-content: start
            padding: 0 
            flex-wrap: wrap

            .header_item_wrapper
                display: flex
                flex-direction: column
                align-items: center
                padding: $r
                width: 45px
                transition: transform .2s ease-in-out   
                position: relative

                &:hover
                    transform: scale(1.5)

                .header_item
                    font-size: 0.7em
                    text-decoration: none

                
                .small-icon
                    height: 15px
                    position: absolute
                    right: 0

            .header_link_gray
                color: $font-gray

            .header_link_white
                color: white

            .logo
                width: 25px
                height: 25px
                margin-bottom: 5px

        @media only screen and (max-width: 1024px)
            .header
                padding: 0 $r * 5
</style>